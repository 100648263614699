import { isAfter, isBefore } from "@/helpers/dates";

import BaseModel from "@/models/BaseModel";

export default class EbePrevisionEtpSchedule extends BaseModel {
  static entity = "ebe_prevision_etp_schedule";
  static resourceUrl = "/financement/ebe_prevision_etp_schedule/";

  static fields() {
    return {
      id: this.uid(),
      nom: this.attr(),
      date_ouverture: this.attr(),
      date_fermeture: this.attr(),
      premier_mois: this.attr(),
      dernier_mois: this.attr(),
      status_log: this.attr(),
    };
  }

  get isEditable() {
    return isAfter(this.date_fermeture, new Date());
  }

  get isDeletable() {
    return isAfter(this.date_ouverture, new Date());
  }

  get isOpen() {
    return isBefore(this.date_ouverture, new Date()) && isAfter(this.date_fermeture, new Date());
  }
}
